<template>
    <div class="page bg-white" id="resourceGrouping">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="lump">
                    <div class="catalogueBox rulesBox">
                        <el-table v-loading="loading2"
                                  :data="catalogueList"
                                  ref="catalogueTable"
                                  size="small"
                                  height="calc(100vh - 224px)"
                                  style="cursor: pointer"
                                  class="table" highlight-current-row
                                  @current-change="handleCurrentChange">
                            <el-table-column prop="groupName" label="组名">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.id!=='' && !scope.row.showIpt">
                                        {{scope.row.groupName}}
                                    </div>
                                    <div v-if="scope.row.id!=='' && scope.row.showIpt">
                                        <el-input
                                                class="input-new-tag"
                                                v-model.trim="scope.row.groupName"
                                                maxlength="20"
                                                ref="saveTagInput"
                                                size="small"
                                                @click="edit(scope.row)"
                                        ></el-input>
                                    </div>
                                    <div v-if="scope.row.id==='' && catalogEditingStatus === 0">
                                        <el-input
                                                class="input-new-tag"
                                                v-if="inputVisible"
                                                v-model.trim="inputValue"
                                                maxlength="20"
                                                ref="saveTagInput"
                                                size="small"
                                                placeholder="回车进行保存"
                                                @change="handleInputConfirm"
                                        ></el-input>
                                        <el-button v-else icon="el-icon-plus"
                                                   v-show="hasPermission('grouping:column:add')" type="primary" plain
                                                   size="small"
                                                   @click="showInput"></el-button>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="100">
                                <template slot-scope="scope"
                                          v-if="scope.row.id!='' && scope.row.id != undefined && !inputVisible">
                                    <el-button size="mini" type="primary" plain icon="el-icon-edit"
                                               v-show="hasPermission('grouping:column:edit')"
                                               @click="catalogEditing(scope.row)"></el-button>
                                    <el-button size="mini" type="danger" plain icon="el-icon-delete"
                                               v-show="hasPermission('grouping:column:del')"
                                               @click="delTemplate($event,scope.row.id)"></el-button>

                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="sizeChangeHandle"
                                @current-change="currentChangeHandle"
                                :current-page="pageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="pageSize"
                                :total="total"
                                background
                                layout="prev, pager, next">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="lump baioge">
                    <el-row :gutter="20" class="headBox m_b1">
                        <el-col :span="6">
                            <el-input size="small" v-model.trim="searchForm.name"
                                      :focus="false"
                                      maxlength="20"
                                      clearable
                                      placeholder="请输入资源名称或编号"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-select style="width: 100%" size="small" v-model="searchForm.type" clearable
                                       placeholder="资源类型">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8">
                            <el-date-picker
                                    style="width:100%"
                                    size="small"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    v-model="searchForm.data"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="6">
                            <el-button type="primary" @click="submitForm('searchForm')" size="small"
                                       icon="el-icon-search">查询
                            </el-button>
                            <el-button @click="resetForm('searchForm')" size="small"
                                       icon="el-icon-refresh-right">
                                重置
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="14">
                            <AdvancedQuery ref="advancedQuery" :moduleId="moduleId"
                                           @getDataList="atlGetDataList"></AdvancedQuery>
                        </el-col>
                        <el-col :span="10" class="text_right">
                            <el-button class="margin-distance" size="small" @click="marshalling">编组</el-button>
                            <el-button class="margin-distance" size="small" @click="Ungroup">撤销编组
                            </el-button>
                            <el-button-group class="m_r1 margin-distance">
                                <el-button size="small" plain icon="el-icon-refresh-left"
                                           @click="refreshAll"></el-button>
                                <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                            </el-button-group>
                        </el-col>
                    </el-row>
                    <el-table
                            :data="contraisalDataList"
                            v-loading="contraisalLoading"
                            size="small"
                            ref="filterTable"
                            height="calc(100vh - 286px)"
                            @selection-change="contSelectionChangeHandle"
                            class="table">
                        <el-table-column
                                type="selection"
                                width="55">
                        </el-table-column>
                        <el-table-column
                                min-width="150"
                                prop="group_name"
                                show-overflow-tooltip sortable
                                label="组名">
                        </el-table-column>
                        <el-table-column v-for="(item,index) in columnList" :key="index+Math.random()"
                                         :prop="item.fieldEname" width="160"
                                         show-overflow-tooltip sortable
                                         :label="item.fieldName">
                            <template slot-scope="scope">
                                        <span v-if="item.fieldEname=='resource_type'">
                                            {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                                        </span>
                                <span v-else-if="item.fieldEname=='resource_state'">
                                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                                        </span>
                                <span v-else-if="item.fieldEname=='on_collection'">
                                            {{scope.row.on_collection ===  0 ? '否' : '是'}}
                                        </span>
                                <span v-else-if="item.fieldEname=='update_user'">
                                            {{scope.row.updateUserName}}
                                    </span>
                                <span v-else>{{scope.row[item.fieldEname]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed="right"
                                width="200"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" v-show="hasPermission('admin:resources:view')"
                                           plain @click="see(scope.row)">查看
                                </el-button>
                                <el-button size="mini" type="primary" plain
                                           v-show="hasPermission('grouping:list:marshalling')"
                                           @click="marshallingOne(scope.row)">
                                    编组
                                </el-button>
                                <el-button size="mini"
                                           type="primary" plain
                                           v-show="hasPermission('grouping:list:cancelMarshalling')"
                                           @click="UngroupOne($event,scope.row)">撤销编组
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="contSizeChangeHandle"
                            @current-change="contCurrentChangeHandle"
                            :current-page="contPageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="contPageSize"
                            :total="contTotal"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
                <!--列表设置拖拽排序弹窗-->
                <DraggablePop :moduleId="moduleId" :setShow="appraisalSetShow"
                              @getTbList="getTbList"></DraggablePop>
                <!--分组弹窗-->
                <el-dialog
                        title="编组"
                        :visible.sync="grouping"
                        width="30%"
                        :close-on-click-modal="tanchuan"
                        :before-close="groupingHandleClose">
                    <el-form size="small"
                             :rules="rules1" ref="marshallingwe" :model="marshallingwe">
                        <el-form-item label="分组名" prop="groupName">
                            <el-select
                                    v-model="marshallingwe.groupName"
                                    filterable
                                    allow-create
                                    remote
                                    default-first-option
                                    :remote-method="getAllGroupName"
                                    :loading="groupingLoading"
                                    placeholder="请选择分组名">
                                <el-option
                                        v-for="item in optionsName"
                                        :key="item.id"
                                        :label="item.groupName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-row :gutter="20">
                        <el-col :span="2" :offset="4">
                            <el-button type="primary" size="small" @click="zhixing('marshallingwe')">确定</el-button>
                        </el-col>
                        <el-col :span="2" :offset="10">
                            <el-button size="small" @click="groupingHandleClose('marshallingwe')">取消</el-button>
                        </el-col>
                    </el-row>
                </el-dialog>
            </el-col>
        </el-row>
        <!--        三维-->
        <!--查看-->
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>

        <!--查看-->
        <ViewForm ref="viewForm"></ViewForm>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import ViewThreeForm from '../gather/resource/three/viewThreeForm' // 三维查看弹窗
    import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
    export default {
        name: "resourceGrouping",
        components: {DraggablePop, AdvancedQuery, ViewThreeForm, ViewForm},
        data() {
            return {
                searchForm: {
                    name: '',
                    type: '',
                    data: '',
                },
                options: [
                    {
                        value: '',
                        label: '全部',
                    },
                    {
                        value: 1,
                        label: '图片',
                    },
                    {
                        value: 5,
                        label: '文献',
                    },
                    {
                        value: 3,
                        label: '视频',
                    },
                    {
                        value: 2,
                        label: '音频',
                    },
                    {
                        value: 0,
                        label: '三维文件',
                    },
                    {
                        value: 4,
                        label: '出版物',
                    },
                    {
                        value: 6,
                        label: '文件',
                    },
                    {
                        value: 7,
                        label: '其它',
                    },
                ],
                catalogueList: [], //目录栏数据
                loading2: false, //左边目录栏的表的加载
                inputVisible: false,
                inputValue: '',
                pageNo: 1,
                pageSize: 10,
                total: 0,

                muluId: '',//目录选中id
                contraisalDataList: [],
                contraisalLoading: false,
                contDataListSelections: [],
                contSelectAllShow: false,//是否全选
                moduleId: '958321204450557952',   // 当前请求拖拽排序数据id
                appraisalSetShow: false,
                contPageNo: 1,
                contPageSize: 10,
                contTotal: 0,
                //分组
                grouping: false,
                tanchuan: false,
                marshallingwe: {
                    groupName: '',
                },
                rules1: {
                    groupName: [
                        {required: true, message: '请选择分组名', trigger: 'blur'}
                    ],
                },
                optionsName: [],
                groupingLoading: false,
                selectIds: [],
                columnList: [],
                searchTpye: 0,
                highData: null,
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
                catalogEditingStatus: 0,
                onSaveTable: false,//当前正在新增目录
            }
        },
        mounted() {
            this.getCatalogue();
            //this.getColumnList()
        },
        methods: {
            //左边目录页默认高亮第一行
            autoHighlight() {
                this.$refs.catalogueTable.setCurrentRow(this.catalogueList[0])
            },

            catalogEditing(row) {
                if (this.catalogEditingStatus != 0 && this.catalogEditingStatus != row.id) {
                    this.$message.warning("请先完成现在操作项！")
                    return
                }
                row.showIpt = !row.showIpt;
                this.catalogEditingStatus = row.id
                if (row.showIpt) {
                    this.$nextTick(_ => {
                        this.$refs.saveTagInput.$refs.input.focus();
                    });
                }
                if (row.showIpt == false) {
                    this.edit(row)
                }
            },


            showInput() {
                this.inputVisible = true;
                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            // 获取显示字段
            getColumnList() {
                var that = this;
                that.columnList = [];
                that.$axios(this.api.sys.getByOriginalId + this.moduleId, '', "get").then((res) => {
                    if (res.status) {
                        res.data.map(item => {
                            if (item.logicType == 0) {
                                that.columnList.push(item);
                            }
                        })
                    }
                })
            },
            // 刷新按钮回到全部列表
            refreshAll() {
                this.onScene = false
                this.dataListQuery.queryConditions = []
                this.dataListQuery.sceneId = ''
                this.searchForm.name = ''
                this.searchForm.type = ''
                this.searchForm.data = []
                this.contPageNo = 0
                this.getFileList()
            },
            // 获取数据列表-旗下分组
            getFileList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    return
                }
                if (!this.searchForm.data) {
                    this.searchForm.data = ['', '']
                }
                this.searchTpye = 0
                this.contraisalLoading = true
                this.$axios(this.api.grouping.resourceGroupLinkList, {
                    'current': this.contPageNo,
                    'size': this.contPageSize,
                    groupId: this.muluId,
                    resourceNameOrId: this.searchForm.name,
                    resourceType: this.searchForm.type,
                    startTime: this.searchForm.data[0],
                    endTime: this.searchForm.data[1],
                    // resourceState: 资源状态
                }, 'get').then(data => {
                    if (data.status) {
                        this.contraisalDataList = data.data.records
                        this.contTotal = parseInt(data.data.total)
                        this.contraisalLoading = false
                        this.$nextTick(() => {
                            this.$refs.filterTable.doLayout(); // 解决表格错位
                        });
                    }
                })
            },

            //编辑模板
            edit(val) {
                if (val.groupName) {
                    this.loading2 = true
                    let inputForm = {
                        id: val.id,
                        groupName: val.groupName
                    }
                    this.$axios(this.api.grouping.resourcesGroupUpdateById, inputForm, 'post').then(data => {
                        this.loading2 = false
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.catalogEditingStatus = 0
                            this.getCatalogue();
                        }
                    })
                }
                this.getCatalogue();
                val.showIpt = false;
            },
            //新增目录
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.loading2 = true
                    this.$axios(this.api.grouping.save, {groupName: inputValue}, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('目录新增成功')
                            this.getCatalogue();
                        }
                        this.loading2 = false
                    })
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            // 查询左边目录
            getCatalogue() {
                this.loading2 = true
                this.$axios(this.api.grouping.resourcesGroupList, {
                    'templateName': this.catalogueSrh,
                    'current': this.pageNo,
                    'size': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        let that = this;
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading2 = false
                        this.catalogueList.push({id: ""})
                        this.catalogueList.unshift({groupName: "所有资源"})
                        this.catalogueList.forEach(function (v) {
                            that.$set(v, 'showIpt', false)
                        })
                        this.autoHighlight()
                    }
                })
            },
            // 目录单选
            handleCurrentChange(val) {
                if (val.id) {
                    this.contPageNo = 0
                    this.muluId = val.id;
                } else {
                    this.muluId = '';
                }
                this.$refs.catalogueTable.setCurrentRow(val);
                this.getFileList()
            },

            delTemplate(event, id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //鼠标点击按钮后，按钮的默认选中
                    let target = event.target;
                    if (target.nodeName == "SPAN") {
                        target = event.target.parentNode;
                    }
                    target.blur();
                    this.$axios(this.api.grouping.resourcesGroupRemoveByIds + '/' + id, '', 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.data)
                            //this.pageNo = 0
                            if (this.pageNo != 0 && this.catalogueList.length == 3) {
                                this.pageNo = this.pageNo - 1
                            }
                            this.getCatalogue()
                        } else {
                            this.$message.error(data.data)
                        }
                    })
                })
            },

            // 展览每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;

                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.getCatalogue();
                    this.getFileList()
                }
            },

            // 展览当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.getCatalogue();
                    this.getFileList()
                }
            },

            //左边数据表
            //重置
            resetForm(formName) {
                this.searchForm.name = ''
                this.searchForm.type = ''
                this.searchForm.data = []
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                this.$nextTick(() => {
                    this.dataListQuery.queryConditions = []
                    //this.getCatalogue();
                    this.getFileList()
                })
            },

            //查询
            submitForm(formName) {
                /*this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.contPageNo = 0;
                        this.getFileList()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });*/
                this.contPageNo = 0;
                this.getFileList()
            },
            // 高级查询重置页码
            atlGetDataList(data) {
                this.contPageNo = 0
                this.getDataList(data)
            },
            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 0
                }
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.contPageSize
                data.current = this.contPageNo
                data.groupingId = this.muluId
                data.searchNumOrName = this.searchForm.name
                data.type = this.searchForm.type
                data.startTime = this.searchForm.data[0]
                data.endTime = this.searchForm.data[1]

                this.$axios(this.api.rcPage.groupingQueryResource, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.contraisalDataList = res.data.records
                        this.contTotal = parseInt(res.data.total);
                    }
                })

            },

            // 多选
            contSelectionChangeHandle(val) {
                this.contDataListSelections = val;
            },

            // 设置
            setTb() {
                this.appraisalSetShow = true;
            },

            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.appraisalSetShow = state;
                this.columnList = data;
                this.getFileList()
            },

            // 每页数
            contSizeChangeHandle(val) {
                this.contPageSize = val
                this.contPageNo = 1
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    //this.getCatalogue();
                    this.getFileList()
                }
            },

            // 当前页
            contCurrentChangeHandle(val) {
                this.contPageNo = val
                //this.contGetLiat();
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    //this.getCatalogue();
                    this.getFileList()
                }

            },

            //查看
            see(row) {
                if (row.resource_type == 0) { //三维
                    this.$refs.viewThreeForm.init(row);
                } else {
                    this.$refs.viewForm.init(row.id, row.resource_type, this.moduleId);
                }
            },

            //单编组
            marshallingOne(row) {
                this.selectIds = [];
                this.grouping = true;
                this.selectIds.push(row.id);
                this.getAllGroupName()
            },

            //多选编组
            marshalling() {
                if (this.contDataListSelections.length == 0) {
                    this.$message.warning('请选择至少一条数据')
                } else {
                    this.selectIds = [];
                    this.contDataListSelections.map(item => {
                        this.selectIds.push(item.id)
                    })
                    this.getAllGroupName()
                    this.grouping = true;
                }
            },

            //编组名称请求
            getAllGroupName() {
                this.groupingLoading = true;
                this.$axios(this.api.grouping.getAllGroupName, {
                        groupName: this.marshallingwe.groupName
                    }
                    , 'Get').then(res => {
                    if (res.status) {
                        this.optionsName = res.data;
                        this.groupingLoading = false;
                    }
                })
            },

            //多选取消编组
            Ungroup() {
                if (this.contDataListSelections.length == 0) {
                    this.$message.warning('请选择至少一条数据')
                } else {
                    this.selectIds = [];
                    this.contDataListSelections.map(item => {
                        this.selectIds.push(item.id)
                    })
                    this.$confirm('此操作将取消该数据的编组, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.quxiaobianzu();
                    }).catch(() => {
                    });
                }
            },

            //单选取消编组
            UngroupOne(event, row) {
                this.selectIds = [];
                this.selectIds.push(row.id);
                this.$confirm('此操作将取消该数据的编组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //鼠标点击按钮后，按钮的默认选中
                    let target = event.target;
                    if (target.nodeName == "SPAN") {
                        target = event.target.parentNode;
                    }
                    target.blur();
                    this.quxiaobianzu();
                }).catch(() => {
                });
            },

            //取消编组请求
            quxiaobianzu() {
                let groupId = this.muluId == null || this.muluId == '' ? -1 : this.muluId;
                this.$axios(this.api.grouping.removeByIds + groupId, this.selectIds, 'post').then(res => {
                    if (res.status) {
                        this.$message.success('取消编组成功')
                    } else {
                        this.$message.error('取消编组失败')
                    }
                    this.getFileList()
                })
            },

            //分组弹窗关闭
            groupingHandleClose(formName) {
                this.grouping = false;
                this.$refs[formName].resetFields();
            },

            zhixing(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //console.log(this.marshallingwe.groupName)
                        let noxinhjian = false;
                        let obj = {};
                        this.optionsName.map(item => {
                            if (item.id == this.marshallingwe.groupName) {
                                noxinhjian = true;
                            }
                        })
                        if (noxinhjian) {
                            obj = {
                                id: this.marshallingwe.groupName,
                                resourceIds: this.selectIds,
                            }
                        } else {
                            obj = {
                                groupName: this.marshallingwe.groupName,
                                resourceIds: this.selectIds,
                            }
                        }
                        this.$axios(this.api.grouping.save, obj, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('编组成功')
                                this.grouping = false;
                            } else {
                                this.$message.error('编组失败')
                            }
                            this.$refs[formName].resetFields();
                            this.getFileList()
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
    }
</script>

<style scoped>

</style>
